const useLoading = () => useState('loading', () => true)

export function useGlobalLoading() {
  const isLoading = useLoading()

  function setLoading(value) {
    isLoading.value = value
  }

  return {
    isLoading,
    setLoading,
  }
}
