import { convertDate } from '~/helpers/dateFormat'
import { encodedURL } from '~/helpers/encodedURL'
import isoCodes from '~/helpers/isoCodes.js'

export const QR_VARIANTS_LABELS = {
  10: 'component.modal.add.data.batch.label', // batch
  17: 'component.modal.add.data.exp.date.label', // expiration date
  // 22: 'component.modal.add.data.exp.date.label', // expiration date
  21: 'component.modal.add.data.serial.number.label', // serial number,
  22: 'component.modal.add.data.cpv.label',
  13: 'component.modal.add.data.packaging.date.label',
  3100: 'component.modal.add.data.net.weight.label',
  422: 'component.modal.add.data.country.origin.label',
}

const QR_VARIANTS = {
  BATCH: 10,
  SERIAL_NUMBER: 21,
  EXPIRATION_DATE: 17,
  BEST_BEFORE_DATE: 15,
  PRODUCTION_DATE: 11,
  EXPIRATION_DATE_OLD: 22,
  CPV: 22,
  PACKAGING_DATE: 13,
  NET_WEIGHT: 3100,
  COUNTRY_OF_ORIGIN: 422,
  VARIANT: 'qrVariant',
}

const typologies = [
  {
    iri: '/api/qr-typologies/4db1daa1-59a4-4e7a-91b8-b536e103ae17',
    name: 'blank',
  },
  {
    iri: '/api/qr-typologies/7a53f360-7c5e-4709-af9a-b0a44340a8e4',
    name: 'website',
  },
  {
    iri: '/api/qr-typologies/fab7b863-5eac-42df-aa29-7f3d6dba9bb8',
    name: 'builder',
  },
  {
    iri: '/api/qr-typologies/c29a14ed-7e8c-4fed-8135-e223532a51b0',
    name: 'smartpage',
  },
]

// const qrStatus = {
//   'EREN01_NF': 'NotFound',
//   'EREN02': 'Inactive',
//   'EREN03': 'Expired',
//   'EREN04': 'Expired',
//   'EREN05': 'Expired',
//   'EREN06_NA': 'NeedAuth',
//   'EREN07': 'LimitExceeded',
//   'Not Found': 'NotFound',
// }

function processAllergens(allergens) {
  return allergens.reduce(
    (acc, { level, name }) => {
      if (level === 'MAY_CONTAIN')
        acc.mayContain.push(name)
      if (level === 'CONTAINS')
        acc.contains.push(name)
      return acc
    },
    { mayContain: [], contains: [] },
  )
}

function enrichProductInfo(productInfo) {
  return {
    ...productInfo,
    allergens: processAllergens(productInfo.allergens),
  }
}

/**
 * Transforma los datos proporcionados en un objeto con información enriquecida.
 * @param {any} data - Los datos a transformar.
 * @returns {any} El objeto transformado con información enriquecida.
 */
function transformer(data) {
  const { qr, productInfo, linkset = [], isVariant = false, options: { favicon = null, disableTranslation = false } } = data
  const { isFree, qualifiers = [], attributes = [] } = qr

  let design = null
  let error = null
  let redirect = null
  let isSmartpage = false
  let smartpageInfo = null
  let isBlankPage = false
  let isBuilderPage = false

  const setError = (message) => {
    error = message
  }

  const initializeDesign = () => {
    const landing = qr?.design?.landing
    const themeData = {
      ...landing?.theme?.data,
      ...landing?.style,
    }

    design = {
      name: landing?.name || null,
      components: landing?.components || [],
      theme: themeData || null,
    }
  }

  const configureRedirect = () => {
    const components = qr?.design?.landing?.components || []
    if (components.length === 1 && components[0]?.type === 'url') {
      redirect = { ...components[0].inputs }
    }
    else {
      setError('NoContent')
    }
  }

  const setupSmartPage = () => {
    isSmartpage = true

    const expirationKey
            = attributes[QR_VARIANTS.EXPIRATION_DATE]
            || attributes[QR_VARIANTS.EXPIRATION_DATE_OLD]

    if (expirationKey) {
      const convertedDate = convertDate(expirationKey)
      attributes[QR_VARIANTS.EXPIRATION_DATE]
        ? (attributes[QR_VARIANTS.EXPIRATION_DATE] = convertedDate)
        : (attributes[QR_VARIANTS.EXPIRATION_DATE_OLD] = convertedDate)
    }

    const beforeDate = attributes[QR_VARIANTS.BEST_BEFORE_DATE]
    const productionDate = attributes[QR_VARIANTS.PRODUCTION_DATE]
    const expirationDate = attributes[QR_VARIANTS.PACKAGING_DATE]
    if (beforeDate)
      attributes[QR_VARIANTS.BEST_BEFORE_DATE] = convertDate(beforeDate)
    if (productionDate)
      attributes[QR_VARIANTS.PRODUCTION_DATE] = convertDate(productionDate)
    if (expirationDate)
      attributes[QR_VARIANTS.PACKAGING_DATE] = convertDate(expirationDate)

    initializeDesign()

    smartpageInfo = {
      qualifiers,
      attributes,
      isVariant,
      ...enrichProductInfo(productInfo),
      gtin: isVariant ? productInfo?.gtin : qr?.pathName,
    }
  }

  const setupBlankPage = () => {
    isBlankPage = true
  }

  if (qr.design) {
    const typology = typologies.find(({ iri }) => iri === qr.design?.typology)

    switch (typology?.name) {
      case 'website':
        configureRedirect()
        break
      case 'smartpage':
        setupSmartPage()
        break
      case 'blank':
        setupBlankPage()
        break
      case 'builder':
        isBuilderPage = true
        initializeDesign()
        break
      default:
        setError('Unknown typology')
        break
    }
  }
  else {
    setError('Missing design')
  }

  return {
    design,
    error,
    redirect,
    smartpageInfo,
    isFree,
    isSmartpage,
    isBlankPage,
    isBuilderPage,
    linkset,
    qr,
    favicon,
    disableTranslation,
  }
}

function isQrVariantPath(route) {
  const routePath = { ...route.query, ...route.params }
  if (!routePath.qrVariant)
    delete routePath.qrVariant
  const isVariant = Object.entries(QR_VARIANTS).some(
    ([, value]) => value in routePath,
  )
  const queryParams = new URLSearchParams(route.query)
  // Se evita enviar el linkType al api/render
  queryParams.delete('linkType')
  const generatedQueryParams = queryParams.toString()
    ? `?${queryParams.toString()}`
    : ''
  const finalURL = `${route.path}${generatedQueryParams}`
  return isVariant && encodeURIComponent(encodeURIComponent(finalURL))
}

function buildHeaders({ path, uniqueVisitCookie = true }) {
  const { public: { appUrl } } = useRuntimeConfig()
  return {
    'Accept-Language': useLocales().locale.value,
    'X-Unique-Visit': uniqueVisitCookie ? 0 : 1,
    'x-forwarded-host': `${appUrl}${path}`,
  }
}

export {
  buildHeaders,
  convertDate,
  encodedURL,
  isoCodes,
  isQrVariantPath,
  QR_VARIANTS,
  transformer,
}
