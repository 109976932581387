import { useGlobalLoading } from '~/composables/loading.js'
import { buildHeaders, isQrVariantPath, transformer } from '~/helpers/index.js'

export const useMainStore = defineStore('main', () => {
  const route = useRoute()

  const landingInfo = ref(null)
  const gtinNumber = computed(() => route.params.shortlink)
  const linkSet = computed(() => landingInfo.value?.linkset || [])
  const productInfo = computed(() => landingInfo.value?.smartpageInfo)
  const qr = computed(() => landingInfo.value?.qr)
  const qrTitle = computed(() => qr.value?.title)
  const qrUrl = computed(() => qr.value?.url)
  const linkType = computed(() => route.query?.linkType)
  const design = computed(() => landingInfo.value?.design)
  const externalURL = computed(() => landingInfo.value?.redirect?.url || null)
  const isALinkSet = computed(() => {
    return ['all', 'linkset'].includes(linkType.value) && linkSet.value.length
  })
  const isBlankPage = computed(() => landingInfo.value?.isBlankPage)
  const favicon = computed(() => landingInfo.value?.favicon ?? '/favicon.ico')
  const disableTranslation = computed(() => landingInfo.value?.disableTranslation)

  async function getLandingFromShortlink() {
    const pathName = isQrVariantPath(route) || gtinNumber.value

    try {
      useGlobalLoading().setLoading(true)
      const { data } = await $fetch(`/api/01/${pathName}`, {
        headers: buildHeaders({ path: route.fullPath }),
      })
      setLandingInfo(data)
    }
    catch {
      throw createError({
        statusCode: 404,
        statusMessage: 'Page Not Found',
        fatal: true,
      })
    }
    finally {
      useGlobalLoading().setLoading(false)
    }
  }

  function redirectToWebsite() {
    if (!externalURL.value)
      return

    let url = externalURL.value.trim()

    if (!/^https?:\/\//i.test(url)) {
      url = `https://${url}`
    }

    if (url.startsWith('http://')) {
      url = url.replace('http://', 'https://')
    }

    navigateTo(url, { external: true })
  }

  function setLandingInfo(data) {
    landingInfo.value = transformer(data)
  }

  return {
    qr,
    qrTitle,
    qrUrl,
    design,
    disableTranslation,
    landingInfo,
    linkSet,
    productInfo,
    isALinkSet,
    gtinNumber,
    isBlankPage,
    setLandingInfo,
    getLandingFromShortlink,
    redirectToWebsite,
    favicon,
  }
})
