<template>
  <div>
    <NuxtPage />
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped>

</style>
