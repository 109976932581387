/**
 * Middleware to handle unique visit tracking and redirection based on the route.
 *
 * @param {object} to - The target route object.
 */
import { buildHeaders, isQrVariantPath } from '~/helpers/index.js'

export default defineNuxtRouteMiddleware(async (to) => {
  // Skip middleware on server
  if (import.meta.server || !to.params?.shortlink)
    return

  const { public: { uniqueVisitCookie } } = useRuntimeConfig()
  const store = useMainStore(usePinia())
  const gtin = to.params.shortlink
  const path = isQrVariantPath(to) || gtin
  const cookie = useCookie(`unique_visit`, {
    expires: new Date(Date.now() + 365000 * 24 * 60 * 60 * 1000),
  })
  try {
    const response = await useFetch(`/api/01/${path}`, {
      headers: buildHeaders({ path: to.fullPath, uniqueVisitCookie: cookie.value }),
    })

    // Set unique visit cookie if not already set
    if (!cookie.value) {
      cookie.value = `${uniqueVisitCookie}_${gtin}`
    }

    // Redirect if response contains a href
    if (response.data.value?.href) {
      await navigateTo(response.data.value.href, { replace: true, external: true })
    }

    // Set landing information in the store
    store.setLandingInfo(response.data.value.data)
  }
  catch (e) {
    console.log(e)
    throw createError({
      statusCode: 404,
      statusMessage: 'Page Not Found',
      fatal: true,
    })
  }
})
