import { createI18n } from 'vue-i18n'
import ar from '~/locales/ar.json'
import bn from '~/locales/bn.json'
import ca from '~/locales/ca.json'
import de from '~/locales/de.json'
import el from '~/locales/el.json'
import en from '~/locales/en.json'
import es from '~/locales/es.json'
import fr from '~/locales/fr.json'
import hi from '~/locales/hi.json'
import id from '~/locales/id.json'
import it from '~/locales/it.json'
import ja from '~/locales/ja.json'
import ko from '~/locales/ko.json'
import mr from '~/locales/mr.json'
import nl from '~/locales/nl.json'
import pl from '~/locales/pl.json'
import pt from '~/locales/pt.json'
import ru from '~/locales/ru.json'
import th from '~/locales/th.json'
import tr from '~/locales/tr.json'
import ur from '~/locales/ur.json'
import vi from '~/locales/vi.json'
import zh from '~/locales/zh.json'

export default defineNuxtPlugin(async (nuxtApp) => {
  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'en',
    messages: {
      en,
      ar,
      bn,
      de,
      es,
      fr,
      hi,
      id,
      it,
      ja,
      ko,
      mr,
      nl,
      pl,
      pt,
      ru,
      th,
      tr,
      ur,
      vi,
      zh,
      ca,
      el,
    },
  })

  nuxtApp.vueApp.use(i18n)
  nuxtApp.provide('i18n', i18n)
})
