export const useLocale = () => useState('locale', () => useDefaultLocale().value)

export function useDefaultLocale(fallback = 'en') {
  const locale = ref(fallback)
  if (import.meta.server) {
    const reqLocale = useRequestHeaders()['accept-language']?.split(',')[0]
    if (reqLocale) {
      locale.value = reqLocale.substring(0, 2)
    }
  }
  else if (import.meta.client) {
    const navLang = navigator.language
    if (navLang) {
      locale.value = navLang
    }
  }
  return locale
}

export function useLocales() {
  const locale = useLocale()
  const locales = ref([
    { id: 'en', name: 'English' },
    { id: 'es', name: 'Español' },
    { id: 'fr', name: 'Français' },
    { id: 'ca', name: 'Català' },
    { id: 'de', name: 'Deutsch' },
    { id: 'it', name: 'Italiano' },
    { id: 'pt', name: 'Português' },
    { id: 'zh', name: '中文' },
    { id: 'ja', name: '日本語' },
    { id: 'ko', name: '한국어' },
    { id: 'ru', name: 'Rus' },
    { id: 'ar', name: 'العربية' },
    { id: 'el', name: 'Ελληνικά' },
    { id: 'nl', name: 'Nederlands' },
    { id: 'pl', name: 'Polski' },
  ])
  const initialLanguage = computed({
    get() {
      return locales.value.find(({ id }) => id === locale.value) || locales.value[0]
    },
    set(value) {
      locale.value = value
    },
  })

  return { locales, locale, initialLanguage }
}
